/*! CSS Used from: http://localhost:3000/vendors~index.css */
* {
  font-family: "Noto Sans";
}
.syngenta-ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #ffffff;
  transition: all 0.2s;
}
.syngenta-ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.syngenta-ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.syngenta-ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.syngenta-ant-layout-sider-light {
  background: #fff;
}
.syngenta-ant-layout-sider-light .syngenta-ant-layout-sider-trigger {
  color: #14151c;
  background: #fff;
  visibility: hidden !important;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #fff;
}

.table-card-container .syngenta-ant-table-wrapper {
  height: 220px;
  padding: 0.5rem 1rem 1rem 1rem;
}

.syngenta-ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #14151c;
  font-variant: tabular-nums;
  line-height: 20px;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  z-index: 0;
  clear: both;
  font-size: 14px;
  background: #fff;
  border-radius: 4px;
}

.syngenta-ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.table-card-container .syngenta-ant-table-thead {
  display: none;
}

.table-card-container .syngenta-ant-table-tbody .syngenta-ant-table-cell {
  padding: 0.5rem;
  border: 0px;
  padding-left: 0px;
}

.table-card-container .syngenta-ant-table-tbody .syngenta-ant-table-cell {
  padding: 0.5rem;
  border: 0px;
  padding-left: 0px;
}

.overview-page-container
  .member-plan-card-container
  .plan-card-container
  .product_badge_card__active {
  background: #c3ead1;
  padding: 1px 4px 1px 4px;
  border: 1px solid #c3ead1;
  border-radius: 4px;
}

.overview-page-container
  .member-plan-card-container
  .plan-card-container
  .product_badge_card
  .syngenta-ant-badge-status-dot {
  display: none;
}

.syngenta-ant-badge-status .syngenta-ant-badge-status-success {
  background-color: #19a04b;
}

.overview-page-container
  .member-plan-card-container
  .plan-card-container
  .product_badge_card__active
  .syngenta-ant-badge-status-text {
  color: #0c612c;
}
.overview-page-container
  .member-plan-card-container
  .plan-card-container
  .product_badge_card
  .syngenta-ant-badge-status-text {
  margin-left: 0px;
  padding: 0px 2px 0px 2px;
  font-weight: 600;
  font-size: 14px;
}

.syngenta-ant-btn {
  padding: 0 15px !important;
  line-height: 20px !important;
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
}

.syngenta-ant-btn.syngenta-ant-btn-default.outlineButton {
  color: #14151c;
  background-color: #ffffff;
  border-color: #c2c7d0;
}

.table-card-container .table-card-footer-container .table-card-footer-button {
  background: #f3f4f6 !important;
  border: none !important;
}

.syngenta-ant-avatar {
  background: #f3f4f6;
  color: #14803c;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 14px;
  font-variant: tabular-nums;
}

.syngenta-ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}

.syngenta-ant-table-thead > tr > th,
.syngenta-ant-table-thead th.syngenta-ant-table-column-sort {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  background: #f3f4f6;
  border-bottom: 1px solid #dfe2e7;
}
.syngenta-ant-table-thead > tr > th {
  color: #14151c !important;
  text-align: left !important;
  background: #f3f4f6 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  transition: background 0.3s ease !important;
  font-weight: 600 !important;
}

.syngenta-ant-divider-vertical {
  border-color: #dfe2e7 !important;
}

.syngenta-ant-dropdown-trigger {
  display: flex;
  align-items: center;
}

.syngenta-ant-menu-item-group-title {
  color: #14151c !important;
  font-weight: 600;
}

.syngenta-ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 20px;
  font-feature-settings: "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: #14151c;
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.syngenta-ant-popover-content .syngenta-ant-popover-title {
  padding: 15px 16px 4px;
  border-bottom: none;
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  color: #14151c;
  font-weight: 500;
}

.syngenta-ant-popover-title {
  padding: 5px 6px 4px !important;
}

.syngenta-ant-tag {
  border-radius: 3px;
  padding: 1px 4px 1px 4px;
  font-weight: 600;
  font-size: 11px;
}

.workspace-selection-dropdown .workspace-name {
  text-overflow: ellipsis !important ;
  white-space: nowrap !important;
  overflow: hidden !important;
  width: 90% !important;
}

.workspace-selection-dropdown
  .workspace-list-menu
  .syngenta-ant-menu-item
  .check-icon-container {
  width: 20px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.workspace-selection-dropdown
  .workspace-list-menu
  .syngenta-ant-menu-item-selected {
  background: #eaf6ff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: -0.0075em !important;
  color: #14151c !important;
}

.workspace-selection-dropdown ul {
  overflow-y: auto;
}

.workspace-selection-dropdown
  .workspace-list-menu
  .syngenta-ant-menu-item-group-title {
  height: auto;
}

.workspace-selection-dropdown
  .workspace-list-menu
  .syngenta-ant-menu-item-selected::before {
  width: 0px !important;
}

.workspace-selection-dropdown
  .workspace-list-menu
  .syngenta-ant-menu-item-group-list {
  height: 30px !important;
  line-height: 2;
  margin: 0;

  :hover {
    background: #f3f4f6 !important;
  }
}

.workspace-selection-dropdown .syngenta-ant-popover-inner {
  padding: 0 !important;
}

.workspace-selection-dropdown
  .syngenta-ant-popover-content
  .syngenta-ant-popover-title {
  padding: 15px 16px 4px !important;
}

.workspace-selection-dropdown
  .syngenta-ant-menu-item.syngenta-ant-menu-item-only-child {
  line-height: 20px !important;
  height: 30px;
  margin: 0 !important;
}

.syngenta-ant-input-affix-wrapper.workspace-search-box {
  background: #f3f4f6 !important;
  color: #14151c !important;
  border: 1px solid #c2c7d0 !important;

  input {
    background: #f3f4f6 !important;
  }

  input::placeholder {
    color: #14151c !important;
  }
}

.workspace-selection-dropdown
  .workspace-list-menu
  .syngenta-ant-menu-item-group-list {
  height: auto !important;
}

.workspace-submenu-dropdown .syngenta-ant-popover-inner {
  padding: 0 !important;

  .syngenta-ant-menu-item {
    margin: 0 !important;
    padding: 0 20px !important;
  }

  .syngenta-ant-menu-item:hover {
    background: #f3f4f6 !important;
  }
}

.account-modal-container .syngenta-ant-modal-footer {
  display: flex;
  justify-content: flex-end;

  .account-modal-cancel-button {
    display: none !important;
  }
}
